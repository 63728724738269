import React, { MutableRefObject } from 'react';
import * as yup from 'yup';

import { GetQuestionParams } from 'redux/actions/quizzes';
import { ProgressiveQuizMode } from 'quizzes/components/mode';
import {
  QuizQuestion,
  NQuizQuestion,
  NProgressiveQuiz,
  NQuizQuestionResponse,
  ResponseOption,
} from 'redux/schemas/models/progressive-quiz';
import {
  ComponentType,
  NLectureComponent,
} from 'redux/schemas/models/lecture-component';
import { LecturePageMode } from 'lecture_pages/components';
import { NAllQuestionViewQuiz } from 'redux/schemas/models/quiz';
import { FieldErrors } from 'react-hook-form';

export type QuizAnswerData = {
  [key: number]: NumberAnswerState | NumberAnswerState[] | ShortAnswerState;
};

type QuizContextValue = {
  reveal?: boolean;
  mode?: ProgressiveQuizMode;
  questions: NQuizQuestion[];
  notMetRequirement?: () => boolean;
  currentQuestionIndex?: number;
  isRequirementEnabled?: boolean;
  currentQuestion?: NQuizQuestion;
  canMakeStructuralChanges?: boolean;
  progressiveQuiz?: NProgressiveQuiz;
  allQuestionViewQuiz?: NAllQuestionViewQuiz;
  isHardDeadlineExpired?: boolean;
  requiredCorrectQuestionsCount?: number;
  closeModal?: (skipCheck?: boolean) => void;
  scrollRef?: React.MutableRefObject<HTMLDivElement>;
  setCurrentQuestionIndex?: (newIndex: number) => void;
  currentQuestionResponse?: NQuizQuestionResponse | null;
  lectureComponent: NLectureComponent<ComponentType.PROGRESSIVE_QUIZ> | NLectureComponent<ComponentType.QUIZ>;
  fetchQuestion?: (params?: Omit<GetQuestionParams, 'questionSetId'>) => Promise<QuizQuestion>;
  savingIndicatorTimeoutRef: React.MutableRefObject<null | NodeJS.Timeout>;
  isSubmitting?: boolean;
  setIsSubmitting?: (isSubmitting: boolean) => void;
  savingStatus?: SavingIndicator;
  setSavingStatus: React.Dispatch<React.SetStateAction<SavingIndicator>>;
  isAllQuestionViewQuiz?: boolean;
  lecturePageMode?: LecturePageMode;
  isEditQuestionMode?: boolean;
  forwardOnQuestionsModalCloseRef?: MutableRefObject<{ callback: Function, restrictModalClose: boolean }>;
  setForwardOnQuestionsModalCloseRef?: Function;
  isContentManagementCollection?: boolean;
};

export default React.createContext<QuizContextValue>(null);

type ShortAnswerState = string;
type NumberAnswerState = number;
type MultipleChoiceAnswerState = {
  [key: number]: boolean
};

export type AnswerStateType = MultipleChoiceAnswerState
| ShortAnswerState
| NumberAnswerState;

type QuestionContextValue = {
  areAllOptionsCorrect?: boolean;
  currentQuestion: NQuizQuestion;
  answerState?: AnswerStateType;
  setAnswerState?: (answerState: AnswerStateType) => void;
  answerInputError?: yup.ValidationError;
  setAnswerInputError?: (answerInputError: yup.ValidationError) => void;
  responseOptions?: ResponseOption[];
  currentQuestionResponse?: NQuizQuestionResponse | null;
};

type MultipleChoiceMultipleAnswerState = number[];
type AllQuestionViewQuizAnswerStateType = ShortAnswerState
| NumberAnswerState
| MultipleChoiceMultipleAnswerState;

export type AllQuestionViewQuizFormStateType = {
  [key: string]: AllQuestionViewQuizAnswerStateType
};

export enum SavingIndicator{
  SAVING_STATUS = 'saving',
  HIDDEN_STATUS = 'hidden',
  SUCCESS_STATUS = 'success',
  ERROR_STATUS = 'error',
}

export const QuestionContext = React.createContext<QuestionContextValue>(null);
