import React, { useEffect, useState } from 'react';
import { AngularContext } from 'react-app';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { isEmpty } from 'underscore';
import t from 'react-translate';
import { css } from '@emotion/core';
import { useFormContext, useWatch } from 'react-hook-form';

import { retryQuiz, revealAnswers, submitQuiz } from 'redux/actions/quizzes';
import { RootState } from 'redux/schemas';
import { NQuizQuestion, QuizSubmission } from 'redux/schemas/models/progressive-quiz';
import { getSkillTagsFromTaggings } from 'redux/selectors/skills-feedback';
import { ComponentType, NLectureComponent } from 'redux/schemas/models/lecture-component';
import { getQuizQuestions } from 'redux/selectors/quizzes';
import { wrapThunkAction } from 'redux/utils';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';

import { gray6 } from 'styles/global_defaults/colors';
import { useTimelineService } from 'timelines/services/react-timeline-service';
import { LecturePageMode } from '..';
import { config } from '../../../../config/pendo.config.json';
import { SavingStatus } from '.';

type SubmitSectionProps = {
  mode: LecturePageMode;
  questionSetId: number;
  lectureComponent: NLectureComponent<ComponentType.QUIZ>;
  setShowCorrectAnswers: Function;
  showCorrectAnswers: boolean;
  saveStatus: SavingStatus;
  setSaveStatus: Function;
  isContentManagementCollection: boolean;
};

const SubmitSection = (props: SubmitSectionProps) => {
  const {
    mode,
    questionSetId,
    lectureComponent,
    showCorrectAnswers,
    setShowCorrectAnswers,
    saveStatus,
    setSaveStatus,
    isContentManagementCollection,
  } = props;

  const { injectServices } = React.useContext(AngularContext);

  const quiz = useSelector((state: RootState) => state.models.quizzes[questionSetId]);
  const skillTags = useSelector(state => getSkillTagsFromTaggings(state, lectureComponent.skillTaggings)) ?? [];
  const questions: NQuizQuestion[] = useSelector(
    (state: RootState) => getQuizQuestions(state, quiz.id, true),
  );
  const isViewMode = mode === LecturePageMode.VIEW;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showResponse, setShowResonse] = useState(false);

  useEffect(() => {
    if (isEmpty(quiz.unsubmittedSubmission) && !isEmpty(quiz?.submission?.responses)) {
      setShowResonse(true);
    }
  }, [quiz?.submission?.responses, quiz.unsubmittedSubmission]);

  const [
    $uibModal,
    TimelinesManager,
  ] = injectServices([
    '$uibModal',
    'TimelinesManager',
  ]);

  const dispatch = useDispatch();
  const timelineService = useTimelineService();
  const { formState, getValues, reset, control } = useFormContext() || {};

  const isHardDeadlineExpired = quiz.hardDeadline && quiz.expired;
  const isUnlimitedAttempts = quiz.maximumAttempts === 0 || !quiz.maximumAttempts;
  const isLastQuizAttempt = (quiz.maximumAttempts === quiz?.submission?.numberOfAttempts + 1)
   || quiz.maximumAttempts === 1;
  const unAnsweredQuestionsCount = Object.values(getValues()).filter((eachAnswer) => !eachAnswer).length;

  const [isRevealingAnswers, setIsRevealingAnswer] = useState(false);

  const allValues = useWatch({
    control,
  });

  useEffect(() => {
    if (saveStatus === SavingStatus.SAVED) {
      setSaveStatus(SavingStatus.SAVE);
    }
    /**
     * Removing saveStatus from dependency array because function need to trigger
     * only when any field value changes
     */
  }, [allValues]);

  const errors = formState?.errors;

  const styles = css`
      .button-container {
        border-bottom: 1px solid ${gray6};
      }
   `;

  const handleSubmit = (isSaveSubmission: boolean) => {
    // Changing key names of answer object from question id to question position index
    let keyMapping = {};
    Object.keys(getValues()).forEach((eachKey, index) => {
      keyMapping = {
        ...keyMapping,
        [eachKey]: questions.find((eachQuestion) => eachQuestion.id.toString() === eachKey)?.displayIndex - 1,
      };
    });

    const userResponse = Object.entries(getValues()).reduce((acc, [key, value]) => {
      const newKey = keyMapping[key];
      acc[newKey] = value;
      return acc;
    }, {});

    const payload = {
      exerciseId: null,
      questionSetId,
      userResponse,
      optionFields: {
      },
      saveSubmission: isSaveSubmission,
    };
    if (isSaveSubmission) {
      setSaveStatus(SavingStatus.SAVING);
    } else {
      setIsSubmitting(true);
    }

    dispatch(submitQuiz(payload)).then((res) => {
      setIsSubmitting(false);
      if (!isSaveSubmission) {
        setSaveStatus(SavingStatus.SAVE);

        if (res.payload?.numberOfAttempts === quiz.maximumAttempts) {
          setShowCorrectAnswers(true);
        }

        const {
          pointsReceived,
          leaderboardRank,
          leaderboardPoints,
          priorLeaderboardRank,
          quiz: { progress },
        } = (res.payload as QuizSubmission);

        timelineService.updateTimeline(lectureComponent.lecturePageId);
        if (pointsReceived || skillTags.length > 0) {
          TimelinesManager.updateComponentPointsAndProgress(
            lectureComponent.lecturePageId,
            lectureComponent.type,
            lectureComponent.id,
            pointsReceived,
            null,
            progress,
          );

          $uibModal.open({
            templateUrl: 'shared/templates/points-modal.html',
            windowClass: 'points-modal',
            controller: 'PointsModalCtrl as vm',
            resolve: {
              extras: { skillTags },
              pointsReceived,
              leaderboardRank,
              leaderboardPoints,
              priorLeaderboardRank,
            },
          });
        } else {
          TimelinesManager.updateComponentProgress(
            lectureComponent.lecturePageId,
            lectureComponent.type,
            lectureComponent.id,
            progress,
          );
        }
      }
    });
  };

  const confirmSubmit = (hasAnsweredQuestionsCount: boolean) => {
    if (hasAnsweredQuestionsCount) {
      const handleConfirm = () => {
        if (isLastQuizAttempt) {
          setTimeout(() => {
            confirmSubmit(false);
          });
        } else {
          handleSubmit(false);
        }
      };
      dispatch(openConfirmationDialog({
        title: t.QUIZZES.QUESTIONS_BLANK_SUBMIT.MESSAGE(unAnsweredQuestionsCount),
        confirmText: t.QUIZZES.QUESTIONS_BLANK_SUBMIT.CONFIRM(),
        cancelText: t.QUIZZES.QUESTIONS_BLANK_SUBMIT.CANCEL(),
        confirmButtonVariant: 'primary',
        onConfirm: handleConfirm,
      }));
    }
    if (!hasAnsweredQuestionsCount) {
      dispatch(openConfirmationDialog({
        title: t.QUIZZES.FINAL_ATTEMPT.MESSAGE(),
        confirmText: t.QUIZZES.FINAL_ATTEMPT.CONFIRM(),
        cancelText: t.QUIZZES.FINAL_ATTEMPT.CANCEL(),
        confirmButtonVariant: 'primary',
        onConfirm: () => handleSubmit(false),
      }));
    }
  };

  const handleRetry = () => {
    reset();
    setShowResonse(false);
    setShowCorrectAnswers(false);
    dispatch(retryQuiz(questionSetId));
  };

  const handleRevealAnswers = () => {
    if (!showCorrectAnswers) {
      setIsRevealingAnswer(true);
      wrapThunkAction(dispatch(revealAnswers({ questionSetId }))).then(() => {
        setShowCorrectAnswers(true);
      }).finally(() => {
        setIsRevealingAnswer(false);
      });
    } else {
      setShowCorrectAnswers(false);
    }
  };

  const getQuestion = (id: string) => questions.find((eachQuestion) => eachQuestion.id.toString() === id);

  const disableSubmit = () => {
    if (showResponse
       || !isViewMode
       || isSubmitting
       || isContentManagementCollection) {
      return true;
    }

    const requiredQuestions = Object.keys(getValues()).filter((eachId) => getQuestion(eachId)?.isRequired);

    const unAnsweredRequiredQuestions = requiredQuestions.filter((eachId) => !getValues()[eachId]);

    if (!isEmpty(errors)
      || !!unAnsweredRequiredQuestions.length) {
      return true;
    }
    return false;
  };

  const disableSave = () => {
    if (showResponse
       || !isViewMode
       || saveStatus === SavingStatus.SAVED
       || saveStatus === SavingStatus.SAVING
       || isSubmitting
       || isContentManagementCollection
    ) {
      return true;
    }
    return false;
  };

  const getAttemptStatus = (status: string) => (
    <p className='text-small text-gray-3 mb-4'>{isUnlimitedAttempts ? t.QUIZZES.UNLIMTED_ATTEMPTS() : status}</p>
  );

  const getSubmitButtonText = () => {
    if (isUnlimitedAttempts) {
      if (isSubmitting) {
        return t.FORM.CHECKING();
      }
      return t.FORM.CHECK();
    }
    if (isSubmitting) {
      return t.FORM.SUBMITTING();
    }
    return t.FORM.SUBMIT();
  };

  const getSaveButtonText = () => {
    if (saveStatus === SavingStatus.SAVING) {
      return t.FORM.SAVING();
    }
    if (saveStatus === SavingStatus.SAVED) {
      return t.FORM.SAVED();
    }
    return t.FORM.SAVE();
  };

  const getButtonContent = () => {
    if (isHardDeadlineExpired) {
      return (
        <>
          <p className='text-small text-gray-3 mb-4'>{t.QUIZZES.DEADLINE_PASSED()}</p>
          <div className='d-flex w-100 pb-6 justify-content-center button-container'>
            <Button
              disabled={!isViewMode || isRevealingAnswers}
              className='ml-2'
              data-qa={showCorrectAnswers ? config.pendo.activities.quiz.hideAnswers : config.pendo.activities.quiz.viewAnswers}
              onClick={handleRevealAnswers}
            >
              {showCorrectAnswers ? t.QUIZZES.HIDE_CORRECT_ANSWERS() : t.QUIZZES.REVEAL_CORRECT_ANSWERS()}
            </Button>
          </div>
        </>
      );
    }

    if (showResponse) {
      if (quiz.maximumAttempts) {
        if (quiz.submission.score === quiz.submission.maxScore) {
          return (
            <>
              {getAttemptStatus(t.QUIZZES.ATTEMPT_PAST(quiz?.submission?.numberOfAttempts, quiz.maximumAttempts))}
              <div className='d-flex w-100 pb-6 justify-content-center button-container'>
                <Button
                  disabled={!isViewMode || isRevealingAnswers}
                  className='ml-2'
                  data-qa={showCorrectAnswers ? config.pendo.activities.quiz.hideAnswers : config.pendo.activities.quiz.viewAnswers}
                  onClick={handleRevealAnswers}
                >
                  {showCorrectAnswers ? t.QUIZZES.HIDE_CORRECT_ANSWERS() : t.QUIZZES.REVEAL_CORRECT_ANSWERS()}
                </Button>
              </div>
            </>
          );
        }
        if (quiz.submission.numberOfAttempts < quiz.maximumAttempts
          && quiz.submission.score < quiz.submission.maxScore) {
          return (
            <>
              {getAttemptStatus(t.QUIZZES.ATTEMPT_PAST(quiz?.submission?.numberOfAttempts, quiz.maximumAttempts))}
              <div className='d-flex w-100 pb-6 justify-content-center button-container'>
                <Button
                  disabled={!isViewMode}
                  onClick={handleRetry}
                  data-qa={config.pendo.activities.quiz.tryAgain}
                >
                  {t.QUIZZES.RETRY()}
                </Button>
              </div>
            </>
          );
        }
        return (
          <>
            {getAttemptStatus(t.QUIZZES.NO_ATTEMPTS_LEFT())}
            <div className='d-flex w-100 pb-6 justify-content-center button-container'>
              <Button
                disabled={!isViewMode || isRevealingAnswers}
                className='ml-2'
                data-qa={showCorrectAnswers ? config.pendo.activities.quiz.hideAnswers : config.pendo.activities.quiz.viewAnswers}
                onClick={handleRevealAnswers}
              >
                {showCorrectAnswers ? t.QUIZZES.HIDE_CORRECT_ANSWERS() : t.QUIZZES.REVEAL_CORRECT_ANSWERS()}
              </Button>
            </div>
          </>
        );
      }
      if (isUnlimitedAttempts) {
        return (
          <>
            {getAttemptStatus(t.QUIZZES.ATTEMPT_PAST(quiz?.submission?.numberOfAttempts, quiz.maximumAttempts))}
            <div className='d-flex w-100 pb-6 justify-content-center button-container'>
              <Button
                disabled={!isViewMode || isRevealingAnswers}
                variant='secondary'
                data-qa={showCorrectAnswers ? config.pendo.activities.quiz.hideAnswers : config.pendo.activities.quiz.viewAnswers}
                onClick={handleRevealAnswers}
              >
                {showCorrectAnswers ? t.QUIZZES.HIDE_CORRECT_ANSWERS() : t.QUIZZES.REVEAL_CORRECT_ANSWERS()}
              </Button>
              <Button
                disabled={!isViewMode || isRevealingAnswers}
                className='ml-2'
                data-qa={config.pendo.activities.quiz.tryAgain}
                onClick={handleRetry}
              >
                {t.QUIZZES.RETRY()}
              </Button>
            </div>
          </>
        );
      }
    }
    return (
      <>
        {getAttemptStatus(t.QUIZZES.ATTEMPT((quiz?.submission?.numberOfAttempts ?? 0) + 1, quiz.maximumAttempts))}
        <div className='d-flex w-100 pb-6 justify-content-center button-container'>
          <Button
            disabled={disableSave()}
            variant='secondary'
            data-qa={config.pendo.activities.quiz.save}
            onClick={() => handleSubmit(true)}
          >
            {getSaveButtonText()}
          </Button>
          <Button
            disabled={disableSubmit()}
            className='ml-2'
            data-qa={config.pendo.activities.quiz.submit}
            onClick={() => ((isLastQuizAttempt || !!unAnsweredQuestionsCount) ? confirmSubmit(!!unAnsweredQuestionsCount) : handleSubmit(false))}
          >
            {getSubmitButtonText()}
          </Button>
        </div>
      </>
    );
  };


  return (
    <div css={styles} className='pt-6 d-flex flex-column align-items-center w-100'>
      {getButtonContent()}
    </div>
  );
};

export default SubmitSection;
