
import React from 'react';
import { useSelector } from 'react-redux';
import t from 'react-translate';
import { isEmpty } from 'underscore';

import { RootState } from 'redux/schemas';
import { CourseAliases } from 'redux/schemas/models/course';
import { ComponentType } from 'redux/schemas/models/lecture-component';
import { NProgressiveQuiz } from 'redux/schemas/models/progressive-quiz';
import { NAllQuestionViewQuiz } from 'redux/schemas/models/quiz';
import { getCourseAliases } from 'redux/selectors/course';
import { getPointsConfiguration } from 'redux/selectors/points-configurations';

import ActivityPoints from 'shared/components/activity/activity-points';
import NvIcon from 'shared/components/nv-icon';
import { LecturePageMode } from 'lecture_pages/components';

type PointsComponentProps = {
  quiz: NAllQuestionViewQuiz & NProgressiveQuiz;
  type: ComponentType.QUIZ | ComponentType.PROGRESSIVE_QUIZ;
  mode: LecturePageMode;
};

const PointsComponent = (props: PointsComponentProps) => {
  const { quiz, type, mode } = props;

  const { hardDeadlinePassed } = quiz;
  const isViewMode = mode === LecturePageMode.VIEW;
  const isCompleted = quiz.progress === 'completed';
  const isInProgress = quiz.progress === 'in_progress';
  const isMissed = quiz.progress === 'missed' || hardDeadlinePassed;
  const notStarted = quiz.progress === 'not_started';

  const isAllQuestionViewQuiz = type === ComponentType.QUIZ;
  const correctAnswersCount = isAllQuestionViewQuiz ? (quiz?.submission?.score ?? 0) : quiz.correctAnswersCount;

  const getPointConfigurationId = () => quiz?.pointsConfiguration;

  const pointsConfiguration = useSelector((state) => (
    getPointsConfiguration(state, getPointConfigurationId() || 0)
  ));
  const aliases = useSelector<RootState, CourseAliases>(
    (state: RootState) => getCourseAliases(state),
  );

  const isRequirementEnabled = pointsConfiguration && !pointsConfiguration?.rewardsPointsProportionally;
  const requiredCorrectQuestionsCount = isRequirementEnabled ? Math.ceil(quiz.answerableQuestionsCount * pointsConfiguration?.threshold) : 0;

  const pointsText = (() => {
    const notCompletedText = pointsConfiguration?.rewardsPointsProportionally ? t.POINTS.UP_TO_X_POINTS({
      maxPoints: quiz.totalPoints?.[0],
      pointsAliasSingularized: aliases.pointsAliases.pointAlias,
      pointsAliasPluralized: aliases.pointsAliases.pointsAlias,
    }) : undefined;

    if (isViewMode) {
      if (isCompleted) {
        return undefined;
      }

      return notCompletedText;
    }
    return notCompletedText;
  })();

  const label = (() => {
    const awardForPassingScore = !pointsConfiguration?.rewardsPointsProportionally;

    if (awardForPassingScore) {
      if (isViewMode) {
        if (isCompleted || isMissed) {
          if (correctAnswersCount === quiz.answerableQuestionsCount) {
            return t.POINTS.ALL_CORRECT();
          }

          return isMissed
            ? t.POINTS.GET_X_OR_MORE_CORRECT_ANSWERS(requiredCorrectQuestionsCount)
            : t.TIMED_QUIZZES.ANSWERED_X_CORRECT(correctAnswersCount, quiz.answerableQuestionsCount);
        }
        if (isAllQuestionViewQuiz) {
          if (!isEmpty(quiz?.submission?.responses)) {
            return t.POINTS.NEED_MORE_FOR_POINTS(
              requiredCorrectQuestionsCount.toString(),
              correctAnswersCount.toString(),
            );
          }
          return t.POINTS.GET_X_OR_MORE_CORRECT_ANSWERS(requiredCorrectQuestionsCount);
        }

        if (isInProgress && !quiz.resumeQuiz) {
          return t.POINTS.NEED_MORE_FOR_POINTS(
            requiredCorrectQuestionsCount.toString(),
            correctAnswersCount.toString(),
          );
        }

        return t.POINTS.GET_X_OR_MORE_CORRECT_ANSWERS(requiredCorrectQuestionsCount);
      }

      return t.POINTS.GET_X_OR_MORE_CORRECT_ANSWERS(requiredCorrectQuestionsCount);
    }
    if (!awardForPassingScore && isViewMode) {
      if (isCompleted) {
        if (correctAnswersCount === quiz.answerableQuestionsCount) {
          return t.POINTS.ALL_CORRECT();
        }
        return t.TIMED_QUIZZES.ANSWERED_X_CORRECT(correctAnswersCount, quiz.answerableQuestionsCount);
      }
    }

    return undefined;
  })();

  if (!!quiz.answerableQuestionsCount
    && (pointsConfiguration && !!pointsConfiguration?.points)) {
    const questionsText = (() => {
      if (notStarted || isMissed) {
        return t.QUIZZES.QUESTIONS(quiz.answerableQuestionsCount.toString());
      }

      if (correctAnswersCount < requiredCorrectQuestionsCount) {
        return t.POINTS.GET_X_OR_MORE_CORRECT_ANSWERS(requiredCorrectQuestionsCount);
      }

      return undefined;
    })();

    if (pointsConfiguration?.rewardsPointsProportionally
        && isCompleted) {
      return (
        <div className='activity-points'>
          <div className='d-flex'>
            {quiz.pointsReceived ? (
              <div className='d-flex text-small font-weight-bold'>
                <NvIcon icon='highlight' size='xss-smallest' className='text-success mr-2' />
                <span>
                  <span className='text-success'>{quiz.pointsReceived}</span>
                  { (quiz.totalPoints?.[0] > quiz.pointsReceived) && (
                  <span className='text-gray-2'>
                    /
                    {quiz.totalPoints?.[0]}
                  </span>
                  )}
                </span>
              </div>
            ) : (
              <div className='d-flex text-small font-weight-bold'>
                <NvIcon icon='points' size='xss-smallest' className='text-gray-2 mr-2' />
                <span className='text-gray-2'>
                  {t.POINTS.UP_TO_X_POINTS({
                    maxPoints: quiz.totalPoints?.[0],
                    pointsAliasSingularized: aliases.pointsAliases.pointAlias,
                    pointsAliasPluralized: aliases.pointsAliases.pointsAlias,
                  })}
                </span>
              </div>
            )}
            {label && (
            <div>
              <span className='dot-separator' />
              <span className='text-gray-3'>
                {label}
              </span>
            </div>
            )}
          </div>
        </div>
      );
    }
    return (
      <div className='activity-points'>
        <ActivityPoints
          label={label}
          pointsText={pointsText}
          preLabelText={questionsText}
          totalPoints={quiz.totalPoints?.[0]}
          pointsReceived={quiz.pointsReceived}
        />
      </div>
    );
  }

  const questionsText = (() => {
    if (notStarted || isMissed) {
      return t.QUIZZES.QUESTIONS(quiz.answerableQuestionsCount.toString());
    }

    if (correctAnswersCount < quiz.answerableQuestionsCount) {
      return t.POINTS.GOT_POINTS('', quiz.answerableQuestionsCount.toString(), correctAnswersCount.toString());
    }

    if (correctAnswersCount === quiz.answerableQuestionsCount) {
      return t.POINTS.ALL_CORRECT();
    }

    return undefined;
  })();

  return (
    <div className='activity-points'>
      <span className='text-gray-2'>
        {questionsText}
      </span>
    </div>
  );
};

export default PointsComponent;
