import React, { useEffect } from 'react';
import t from 'react-translate';
import { css } from '@emotion/core';
import { useFormContext } from 'react-hook-form';

// redux
import { NQuizQuestion } from 'redux/schemas/models/progressive-quiz';
import { useSelector } from 'react-redux';
import { getFlatCourseAliases } from 'redux/selectors/course';

import ProgressiveQuizContext, { QuestionContext } from 'quizzes/components/context';
import NvIcon from 'shared/components/nv-icon';
import NvPopover from 'shared/components/nv-popover';
import useQuizModeAndQuestionType from 'quizzes/hooks/use-quiz-mode-and-question-type';
import
{ danger,
  gray2,
  gray5,
  primary,
  success,
  white,
} from 'styles/global_defaults/colors';
import { quarterSpacing, tripleSpacing } from 'styles/global_defaults/scaffolding';
import AnswerFeedback from 'quizzes/components/feedback-components/answer-feedback';
import ViewAnswerFeedBack from 'quizzes/components/feedback-components/view-answer-feedback';
import { removeDecimalForInteger } from 'quizzes/components/utils';
import { AllQuestionQuizQuestion } from 'redux/schemas/models/quiz';
import NvTextInput from 'shared/components/inputs/nv-text-input';
import NumberAnswerSettingInput from './number-answer-setting-input';

type QuizQuestionProps = {
  currentQuestion: NQuizQuestion | AllQuestionQuizQuestion;
};

const NumberAnswerSection = (props: QuizQuestionProps) => {
  const { currentQuestion } = props;
  const {
    answerState,
    setAnswerState,
    responseOptions,
  } = React.useContext(QuestionContext);

  const {
    isAllQuestionViewQuiz,
    currentQuestionResponse,
    isHardDeadlineExpired,
    isContentManagementCollection,
  } = React.useContext(ProgressiveQuizContext);

  const { formState, setValue, trigger } = useFormContext() || {};
  const errors = formState?.errors;

  const courseAliases = useSelector(getFlatCourseAliases);

  const {
    isEditMode,
    isAnswerMode,
    isReviewMode,
  } = useQuizModeAndQuestionType();

  const [correctAnswerFeedback, setCorrectAnswerFeedback] = React.useState(responseOptions[0]?.explanation);
  const [incorrectAnswerFeedback, setIncorrectAnswerFeedback] = React.useState(responseOptions[0]?.secondaryExplanation);
  const isTopRangePresent = !!responseOptions[0]?.topOfRange
    && (responseOptions[0].topOfRange !== responseOptions[0]?.bottomOfRange);

  const [isAnswerInRange, setIsAnswerInRange] = React.useState(false);
  const [bottomOfRange, setBottomOfRange] = React.useState(removeDecimalForInteger(responseOptions[0]?.bottomOfRange) || '');
  const [topOfRange, setTopOfRange] = React.useState(isTopRangePresent ? removeDecimalForInteger(responseOptions[0]?.topOfRange) : '');
  const [triggerShowFeedback, setTriggerShowFeedback] = React.useState(false);

  const showFeedbackIcon = !(correctAnswerFeedback !== null && incorrectAnswerFeedback !== null);

  useEffect(() => {
    if (isAllQuestionViewQuiz) {
      if (currentQuestionResponse) {
        setValue(currentQuestion.id.toString(), currentQuestionResponse.response);
      }
    }
  }, [
    currentQuestion.displayIndex,
    currentQuestion.id,
    currentQuestionResponse,
    isAllQuestionViewQuiz,
    setValue,
  ]);

  useEffect(() => {
    if (isTopRangePresent) {
      setIsAnswerInRange(true);
    }
  }, [isTopRangePresent]);

  const getBorderColor = () => {
    if (currentQuestionResponse) {
      if (currentQuestionResponse.isCorrect) {
        return success;
      }
      return danger;
    }
    return gray5;
  };

  const styles = css`
    .input-style {
      font-size: 16px;
      border-color: ${getBorderColor()};
      border-radius: ${quarterSpacing}px;
      background: transparent !important;

      ::placeholder {
        color: ${gray2};
      }
    }
    .answer-input-group {
      .bs4-input-group-text {
        background: transparent;
        border-top-right-radius: ${quarterSpacing}px;
        border-bottom-right-radius: ${quarterSpacing}px;
      }
    }
    .input-style:focus {
      border-color: ${primary};
    }
    .input-style {
      font-size: 16px;
      border-radius: ${quarterSpacing}px;
      background-color: ${white};

      ::placeholder {
        color: ${gray2};
      }
    }
    .number-setting-container {
      height: ${tripleSpacing}px;
      .dash-component {
        width: 10%;
      }
    }
  `;

  const getValue = () => {
    if (currentQuestionResponse) {
      return currentQuestionResponse.response;
    }
    if (!isAllQuestionViewQuiz) {
      if (answerState === null) {
        return '';
      }
      return answerState?.toString();
    }
    return null;
  };

  const handleAnswerChange = (e) => {
    e.persist();
    if (!isAllQuestionViewQuiz) {
      setAnswerState(e.target.value);
    } else {
      setValue(currentQuestion.id.toString(), e?.target?.value);
      trigger(currentQuestion.id.toString());
    }
  };

  const isDisabled = !isAnswerMode
   || !!currentQuestionResponse
   || isHardDeadlineExpired
   || isContentManagementCollection;

  return (
    <div css={styles}>
      <div className='w-100 mb-5'>
        { !isEditMode && (
          <div className='mt-5 mx-auto'>
            <NvTextInput
              inputClassName='py-5 pl-2 w-100 input-style'
              className='answer-input-group'
              disabled={isDisabled}
              placeholder={(isReviewMode && !getValue()) ? '' : '0'}
              value={getValue() as string}
              type='number'
              name={currentQuestion.id.toString()}
              errorValidationPopoverTitle={t.VALIDATION.ERROR()}
              withForm={isAllQuestionViewQuiz}
              onChange={handleAnswerChange}
              showErrorWithoutFocus
            />
          </div>
        )}
        { currentQuestionResponse && (
          <ViewAnswerFeedBack currentQuestion={currentQuestion} />
        )}
      </div>
      { isEditMode && (
      <React.Fragment>
        <div className='d-flex pt-5'>
          <p className='semi-bold mb-0'>{t.QUIZZES.NUMBER_ANSWER_QUESTION.CORRECT_NUMBER()}: </p>
          <NvPopover
            className='d-flex'
            showOnHover
            placement='top'
            content={(
              <div className=''>
                {t.QUIZZES.NUMBER_ANSWER_QUESTION.SETTING_NUMBER_ANSWER_INFO(courseAliases)}
              </div>
              )}
          >
            <NvIcon
              className='d-flex align-items-center'
              icon='info ml-1'
              size='xs-smallest text-primary'
            />
          </NvPopover>
        </div>
        <div className='mt-2 number-setting-container d-flex'>
          <NumberAnswerSettingInput
            setTriggerShowFeedback={setTriggerShowFeedback}
            isAnswerInRange={isAnswerInRange}
            setIsAnswerInRange={setIsAnswerInRange}
            topOfRange={topOfRange}
            bottomOfRange={bottomOfRange}
            setBottomOfRange={setBottomOfRange}
            setTopOfRange={setTopOfRange}
            currentQuestion={currentQuestion}
            showFeedbackIcon={showFeedbackIcon}
            isBottomRange
          />
          {isAnswerInRange && (
          <React.Fragment>
            <div className='dash-component d-flex align-items-center justify-content-center'>
              <NvIcon icon='dash' size='small' className='d-flex align-items-center text-gray-4' />
            </div>
            <NumberAnswerSettingInput
              setTriggerShowFeedback={setTriggerShowFeedback}
              isAnswerInRange={isAnswerInRange}
              setIsAnswerInRange={setIsAnswerInRange}
              topOfRange={topOfRange}
              bottomOfRange={bottomOfRange}
              setBottomOfRange={setBottomOfRange}
              setTopOfRange={setTopOfRange}
              currentQuestion={currentQuestion}
              showFeedbackIcon={showFeedbackIcon}
              isBottomRange={false}
            />
          </React.Fragment>
          )}
        </div>
        <AnswerFeedback
          currentQuestion={currentQuestion}
          correctAnswerFeedback={correctAnswerFeedback}
          incorrectAnswerFeedback={incorrectAnswerFeedback}
          setCorrectAnswerFeedback={setCorrectAnswerFeedback}
          setIncorrectAnswerFeedback={setIncorrectAnswerFeedback}
          triggerShowFeedback={triggerShowFeedback}
          setTriggerShowFeedback={setTriggerShowFeedback}
        />
      </React.Fragment>
      )}
    </div>
  );
};

export default NumberAnswerSection;
