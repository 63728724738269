import React, { useRef } from 'react';
import t from 'react-translate';
import { AngularServicesContext } from 'react-app';
import { isEmpty } from 'underscore';

// redux
import { useAppDispatch } from 'redux/store';
import { editQuizQuestion } from 'redux/actions/quizzes';

import NvFroala from 'froala/components/nv-froala';
import { SavingRegistryContext } from 'shared/hooks/use-saving-registry';
import ProgressiveQuizContext, { QuestionContext, SavingIndicator } from 'quizzes/components/context';
import ResponsivelyEmbeddedAngularHTML from 'shared/components/responsively-embedded-angular-html';
import useQuizModeAndQuestionType from 'quizzes/hooks/use-quiz-mode-and-question-type';
import { config } from '../../../config/pendo.config.json';

type Props = {
  className?: string;
};

const defaultQuestionTemplate = '<p class="froala-style-medium"><br /></p>';

const QuizQuestionContent = (props: Props) => {
  const { className } = props;

  const dispatch = useAppDispatch();
  const mountedRef = useRef(false);
  const {
    savingIndicatorTimeoutRef,
    setSavingStatus,
  } = React.useContext(ProgressiveQuizContext);

  const {
    isStatement,
    isEditMode,
  } = useQuizModeAndQuestionType();

  const { currentQuestion } = React.useContext(QuestionContext);
  const angularServices = React.useContext(AngularServicesContext);
  const { registerSaving } = React.useContext(SavingRegistryContext);
  const [value, setValue] = React.useState(currentQuestion.questionText || defaultQuestionTemplate);

  const handleChange = (html) => {
    const patch = {
      questionText: html,
    };
    setValue(html);
    const unregister = registerSaving();
    setSavingStatus(SavingIndicator.SAVING_STATUS);
    clearTimeout(savingIndicatorTimeoutRef.current);
    dispatch(editQuizQuestion({
      patch,
      id: currentQuestion.id,
    })).then((res) => {
      if (isEmpty(res.error)) {
        setSavingStatus(SavingIndicator.SUCCESS_STATUS);
      } else {
        setSavingStatus(SavingIndicator.ERROR_STATUS);
      }
    }).finally(() => {
      unregister();
      savingIndicatorTimeoutRef.current = setTimeout(() => {
        setSavingStatus(SavingIndicator.HIDDEN_STATUS);
      }, 2000);
    });
  };

  React.useEffect(() => {
    mountedRef.current = true;
  }, []);

  const getPlaceholder = () => {
    if (isStatement) {
      return t.QUIZZES.STATEMENT.TYPE_STATEMENT();
    }
    return t.QUIZZES.QUESTION_PLACEHOLDER();
  };

  if (isEditMode) {
    return (
      <NvFroala
        allowToolbar
        value={value}
        minHeight={80}
        className={className}
        onChange={handleChange}
        placeholder={getPlaceholder()}
        dataQa={config.pendo.activities.progressiveQuiz.questionModal.questionTitle}
        dataQaId={`${config.pendo.activities.progressiveQuiz.questionModal.questionTitle}_${currentQuestion.id}`}
      />
    );
  }

  return (
    <ResponsivelyEmbeddedAngularHTML
      className={className}
      angularServices={angularServices}
      template={currentQuestion.questionText}
    />
  );
};

export default QuizQuestionContent;
