import React, { useState } from 'react';
import { css } from '@emotion/react';
import t from 'react-translate';
import { isEmpty, omit, pick } from 'underscore';
import { useSelector } from 'react-redux';

import { QuizAIQuestion } from 'redux/schemas/models/quiz';
import { ComponentType } from 'redux/schemas/models/lecture-component';
import { getActivity } from 'redux/selectors/timeline';
import { ActivityType } from 'redux/schemas/models/activity';
import { useAppDispatch } from 'redux/store';
import { setNovoAIProgressiveQuizQuestionModalId } from 'redux/actions/lecture-pages';

import NvModal, { ModalType } from 'shared/components/nv-modal';
import QuizAiQuestionsModal from 'lecture_pages/components/quiz/ai-questions-modal';
import { QuizTypeSelectionModalContent } from 'quizzes/components/quiz-basic-setting-modal';
import GenerateButton from '../shared/generate-button';
import useAIComponentsGenerator from '../../hooks/use-ai-components-generator';

const MODAL_WIDTH = 1080;

const modalStyles = css`
  .bs4-modal-body  {
    padding: 0 140px;
  }
`;

const GenerateQuiz = () => {
  const [showQuestionModal, setShowQuestionModal] = useState<boolean>(false);
  const [showQuizTypeModal, setShowQuizTypeModal] = useState<boolean>(false);
  const [questions, setQuestions] = useState<QuizAIQuestion[]>([]);

  const { createAIComponent } = useAIComponentsGenerator();
  const dispatch = useAppDispatch();

  const novoAIProperties = useSelector(state => state.app.lecturePage.novoAI);
  const { lectureComponentToReplace, itemTrueType } = useSelector(state => state.app.lecturePage.novoAI);
  const quizActivity = useSelector((state) => getActivity(state, novoAIProperties?.lectureComponentToReplace?.quiz, ActivityType.QUIZ));
  const progressiveQuizActivity = useSelector((state) => getActivity(state, novoAIProperties?.lectureComponentToReplace?.progressiveQuiz, ActivityType.PROGRESSIVE_QUIZ));

  const isEditing = !isEmpty(lectureComponentToReplace);

  const closeQuestionModal = () => {
    setShowQuestionModal(false);
  };

  const onSelectQuestions = (AiQuestions: QuizAIQuestion[]) => {
    closeQuestionModal();

    if (isEditing) {
      const editQuiz = itemTrueType === ComponentType.PROGRESSIVE_QUIZ
        ? onSubmitSingleQuestionViewQuiz
        : onSubmitAllQuestionViewQuiz;

      editQuiz(AiQuestions);
    } else {
      setQuestions(AiQuestions);
      setShowQuizTypeModal(true);
    }
  };

  const onSubmitAllQuestionViewQuiz = (selectedQuestions: QuizAIQuestion[]) => {
    let quizData = {
      // When creating with AI, we are keeping the default type as practice
      // with unlimited quiz attempts.
      graded: false,

      // Removing the FE generated id from the payload.
      questionsAttributes: selectedQuestions.map((que) => omit(que, 'id')),
    };

    if (isEditing) {
      quizData = {
        ...quizData,
        ...pick(quizActivity, [
          'id', 'title', 'expirationDate', 'hardDeadline', 'maximumAttempts',
          'graded', 'isTodo',
        ]),
      };
    }

    createAIComponent({
      itemTrueType: ComponentType.QUIZ,
      modelData: {
        quiz: quizData,
      },
    });
  };

  const onSubmitSingleQuestionViewQuiz = (selectedQuestions: QuizAIQuestion[]) => {
    let quizData = {
      // AI will create quiz with Unlimited quiz attempts and 3 question attempts.
      graded: true,
      maximumAttempts: -1,
      questionMaximumAttempts: 3,

      // Removing the FE generated id from the payload.
      questionsAttributes: selectedQuestions.map((que) => omit(que, 'id')),
    };

    if (isEditing) {
      quizData = {
        ...quizData,
        ...pick(progressiveQuizActivity, [
          'id', 'isTodo', 'hardDeadline', 'expirationDate',
          'maximumAttempts', 'questionMaximumAttempts',
        ]),
      };
    }

    createAIComponent({
      itemTrueType: ComponentType.PROGRESSIVE_QUIZ,
      modelData: {
        progressiveQuiz: quizData,
      },
    }).then((response) => {
      if (isEditing) {
        dispatch(setNovoAIProgressiveQuizQuestionModalId(response?.payload?.id));
      }
    });
  };

  return (
    <React.Fragment>
      <GenerateButton
        onClick={() => setShowQuestionModal(true)}
        className='mt-8'
      />
      <NvModal
        type={ModalType.DYNAMIC}
        width={MODAL_WIDTH}
        header={t.LECTURE_PAGES.COMPONENTS.QUIZ.AI_QUESTIONS.QUIZ_QUESTIONS()}
        show={showQuestionModal}
        body={(
          <QuizAiQuestionsModal
            onClose={closeQuestionModal}
            onSubmit={onSelectQuestions}
          />
        )}
        onClose={() => setShowQuestionModal(false)}
        modalStyles={modalStyles}
      />
      <NvModal
        header={t.QUIZZES.PROGRESSIVE_QUIZ_BASICS.SELECT_QUIZ_TYPE()}
        body={(
          <QuizTypeSelectionModalContent
            onAllQuestionViewSelect={() => onSubmitAllQuestionViewQuiz(questions)}
            onSingleQuestionViewSelect={() => onSubmitSingleQuestionViewQuiz(questions)}
          />
        )}
        type={ModalType.DYNAMIC}
        fullHeight={false}
        show={showQuizTypeModal}
        width={800}
        onClose={() => setShowQuizTypeModal(false)}
      />
    </React.Fragment>
  );
};

export default GenerateQuiz;
