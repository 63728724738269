import moment from 'moment';
import pick from 'lodash/pick';
import t from 'react-translate';
import { css } from '@emotion/core';
import { Button } from 'react-bootstrap';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { DeepPartial } from 'utility-types';

import { RootState } from 'redux/schemas';
import { useAppDispatch } from 'redux/store';
import { wrapThunkAction } from 'redux/utils';
import { gray6 } from 'styles/global_defaults/colors';
import { CourseAliases } from 'redux/schemas/models/course';
import { getProgressiveQuiz } from 'redux/selectors/quizzes';
import { ProgressiveQuizMode } from 'quizzes/components/mode';
import NvModal, { ModalType } from 'shared/components/nv-modal';
import { doubleSpacing } from 'styles/global_defaults/scaffolding';
import { ProgressiveQuizAttemptSettingModal } from 'quizzes/components/quiz-basic-setting-modal';
import { setNovoAIProgressiveQuizQuestionModalId, updateLectureComponent } from 'redux/actions/lecture-pages';
import { resetNewComponent } from 'redux/actions/lecture-components';
import { NvDropdownOption } from 'shared/components/inputs/nv-dropdown';
import { getCourseAliases, getCurrentCourse } from 'redux/selectors/course';
import { getPointsConfiguration } from 'redux/selectors/points-configurations';
import { LectureComponentProps, LecturePageMode } from 'lecture_pages/components';
import { ComponentType, LectureComponent } from 'redux/schemas/models/lecture-component';
import BaseLectureComponentContext from 'lecture_pages/directives/components/base-lecture-component/context';
import { resetProgressiveQuizResponses } from 'redux/actions/quizzes';
import QuizModal from 'quizzes/components/quiz-modal';
import { config } from '../../../../config/config.json';
import QuizComponentTitleContent from '../quiz/shared/quiz-lecture-component-title-content';

type ProgressiveQuizLectureComponentType = LectureComponent<ComponentType.PROGRESSIVE_QUIZ>;
type ModalModeDataType = {
  progressiveQuizMode: ProgressiveQuizMode,
  reveal: boolean,
  initialQuestionIndex: number,
};
type ForwardOnQuestionsModalCloseRefType = {
  callback: Function,
  restrictModalClose: boolean,
};
const ProgressiveQuizLectureComponent = (props: LectureComponentProps<ComponentType.PROGRESSIVE_QUIZ>) => {
  const {
    mode,
    currentLecture,
    lectureComponent,
  } = props;

  const dispatch = useAppDispatch();
  const currentLectureId = currentLecture.id;
  const currentCourse = useSelector(getCurrentCourse);
  const isNotViewMode = props.mode !== LecturePageMode.VIEW;
  const isCurrentCourseSelfPaced = currentCourse.isSelfPaced;

  const [showEditBasics, setShowEditBasics] = React.useState(false);
  const [showQuestionModal, setShowQuestionModal] = useState(false);

  const initialModalModeData = {
    progressiveQuizMode: ProgressiveQuizMode.ANSWER,
    reveal: false,
    initialQuestionIndex: 0,
  };
  const [modalModeData, setModalModeData] = useState<ModalModeDataType>(initialModalModeData);

  const currentCatalogId = useSelector((state) => state.app.currentCatalogId);
  const catalogId = useSelector((state: RootState) => state.app.currentCatalogId);

  const { sharedProps, setSharedProps } = useContext(BaseLectureComponentContext);
  const initialSharedProps = React.useRef(sharedProps).current;

  const forwardedOnModalCloseRef = React.useRef<(e: Event, closeModal: Function) => void>();
  const lecturePageId = useSelector((state: RootState) => state.app.lecturePage.currentLectureId);
  const isNewComponent = useSelector((state) => state.app.newComponent === lectureComponent?.id) ?? false;

  const progressiveQuiz = useSelector((state) => getProgressiveQuiz(state, lectureComponent.progressiveQuiz));
  const { expirationDate, hardDeadlinePassed } = progressiveQuiz;

  const course = useSelector((state) => getCurrentCourse(state));
  const lecturePage = useSelector(state => state.models.lecturePages[lecturePageId]);
  const progressiveQuizQuestionModalComponentId = useSelector(state => state.app.lecturePage.novoAI?.progressiveQuizQuestionModalComponentId);

  const pointsConfiguration = useSelector((state) => (
    getPointsConfiguration(state, progressiveQuiz.pointsConfiguration || 0)
  ));

  const isCompleted = progressiveQuiz.progress === 'completed';
  const isInProgress = progressiveQuiz.progress === 'in_progress';
  const isMissed = progressiveQuiz.progress === 'missed' || hardDeadlinePassed;
  const notStarted = progressiveQuiz.progress === 'not_started';

  const lectureComponentId = lectureComponent.id;

  const openEditQuestions = useCallback((extras = {}) => {
    setShowQuestionModal(true);
    setModalModeData({
      progressiveQuizMode: ProgressiveQuizMode.EDIT,
      reveal: false,
      initialQuestionIndex: extras.initialIndex ?? 0,
    });
  }, []);

  const openQuiz = useCallback((isRetry = false) => {
    // Resetting the current question responses when retrying the quiz.
    if (isRetry) {
      dispatch(resetProgressiveQuizResponses({
        questionSetId: progressiveQuiz.id,
      }));
    }
    setShowQuestionModal(true);
    setModalModeData({
      progressiveQuizMode: ProgressiveQuizMode.ANSWER,
      reveal: false,
      initialQuestionIndex: 0,
    });
  }, [dispatch, progressiveQuiz.id]);

  const reviewPreviousAttempt = useCallback((reveal: boolean = false) => {
    setShowQuestionModal(true);
    setModalModeData({
      progressiveQuizMode: ProgressiveQuizMode.REVIEW,
      reveal,
      initialQuestionIndex: 0,
    });
  }, []);

  React.useEffect(() => {
    if (isNewComponent && mode === LecturePageMode.EDIT) {
      openEditQuestions();
      dispatch(resetNewComponent());
    }
  }, [mode, dispatch, isNewComponent, openEditQuestions]);

  // Open edit model when adding new questions to AI created quiz
  useEffect(() => {
    if (progressiveQuizQuestionModalComponentId
      && progressiveQuizQuestionModalComponentId === lectureComponentId
      && !showQuestionModal
    ) {
      dispatch(setNovoAIProgressiveQuizQuestionModalId(null));
      openEditQuestions({ initialIndex: progressiveQuiz.totalQuestions - 1 });
    }
  }, [
    showQuestionModal,
    dispatch,
    lectureComponentId,
    openEditQuestions,
    progressiveQuiz.totalQuestions,
    progressiveQuizQuestionModalComponentId,
  ]);

  const editBasics = () => setShowEditBasics(true);

  const saveComponent = React.useCallback((componentDataPatch: DeepPartial<ProgressiveQuizLectureComponentType['progressiveQuiz']>) => {
    const componentDataUpdate = {
      catalogId: currentCatalogId,
      lecturePageId: currentLectureId,
      componentData: {
        id: lectureComponentId,
        type: lectureComponent.type,
        progressiveQuiz: {
          ...pick(progressiveQuiz, [
            'isTodo',
            'hardDeadline',
            'expirationDate',
            'maximumAttempts',
            'questionMaximumAttempts',
          ]),
          ...componentDataPatch,
        },
      },
    };

    return dispatch(updateLectureComponent(componentDataUpdate as any));
  }, [
    dispatch,
    progressiveQuiz,
    currentLectureId,
    currentCatalogId,
    lectureComponentId,
    lectureComponent.type,
  ]);

  React.useEffect(() => {
    const options: NvDropdownOption[] = [{
      type: 'text',
      callback: editBasics,
      text: t.LECTURE_PAGES.COMPONENTS.DROPDOWN.EDIT_BASICS(),
      disabled: !!lecturePage.isLinked,
      tooltip: {
        enabled: !!lecturePage.isLinked,
        text: t.LECTURE_PAGES.COMPONENTS.SET_UP_FROM_COLLECTION_TOOLTIP(),
        placement: 'left',
        offset: 20,
      },
    }, {
      type: 'text',
      callback: openEditQuestions,
      text: t.LECTURE_PAGES.COMPONENTS.QUIZ.DROPDOWN.EDIT_QUESTIONS(),
      disabled: !!lecturePage.isLinked,
      tooltip: {
        enabled: !!lecturePage.isLinked,
        text: t.LECTURE_PAGES.COMPONENTS.SET_UP_FROM_COLLECTION_TOOLTIP(),
        placement: 'left',
        offset: 20,
      },
    }];

    if (!isCurrentCourseSelfPaced) {
      options.push(
        { type: 'text',
          text: expirationDate ? t.LECTURE_PAGES.COMPONENTS.DROPDOWN.REMOVE_DEADLINE() : t.LECTURE_PAGES.COMPONENTS.DROPDOWN.ADD_DEADLINE(),
          disabled: course.isContentManagementCollection,
          tooltip: {
            enabled: course.isContentManagementCollection,
            text: t.LECTURE_PAGES.COMPONENTS.DROPDOWN.SETUP_IN_LINKED_LESSON_TOOLTIP(),
            placement: 'left',
            offset: 20,
          },
          callback: () => {
            saveComponent({
              expirationDate: expirationDate
                ? null
                : moment(currentLecture.releaseDate).add(1, 'weeks').endOf('day').toISOString(),
            });
          },
        },
      );
    }

    setSharedProps({
      ...initialSharedProps,
      extraOptions: {
        mode: 'prepend',
        renderOnMount: true,
        options,
      },
    });
  }, [
    saveComponent,
    setSharedProps,
    expirationDate,
    openEditQuestions,
    initialSharedProps,
    isCurrentCourseSelfPaced,
    currentLecture.releaseDate,
  ]);

  const styles = css`
    width: 100%;
    padding-top: ${doubleSpacing}px;
    border-bottom: 1px solid ${gray6};
  `;

  const closeModal = () => {
    setShowEditBasics(false);
  };

  const handleModalClose = (e) => {
    forwardedOnModalCloseRef.current?.(e, closeModal);
    if (!e.defaultPrevented) {
      closeModal();
    }
  };

  const forwardOnQuestionsModalCloseRef = useRef<ForwardOnQuestionsModalCloseRefType>(
    {
      callback: () => {},
      restrictModalClose: false,
    },
  );

  const closeQuestionModal = () => {
    forwardOnQuestionsModalCloseRef?.current?.callback();
    if (!forwardOnQuestionsModalCloseRef?.current?.restrictModalClose) {
      setShowQuestionModal(false);
    }
  };

  const attemptsLeft = Math.max(0, progressiveQuiz.maximumAttempts - progressiveQuiz.attemptsCompleted);

  const areUnlimitedAttempts = progressiveQuiz.maximumAttempts === -1;

  const isRequirementEnabled = pointsConfiguration && !pointsConfiguration.rewardsPointsProportionally;

  const requiredCorrectQuestionsCount = isRequirementEnabled ? Math.ceil(progressiveQuiz.answerableQuestionsCount * pointsConfiguration.threshold) : 0;

  const isFinished = (() => {
    if (isMissed) {
      return true;
    }

    const areAllQuestionsCorrect = progressiveQuiz.answerableQuestionsCount === progressiveQuiz.correctAnswersCount;

    if (areAllQuestionsCorrect && !progressiveQuiz.resumeQuiz) {
      return true;
    }

    if (!areUnlimitedAttempts && !attemptsLeft) {
      return true;
    }

    return false;
  })();

  const canAnswer = !isFinished;

  return (
    <div className='d-flex flex-column align-items-center pb-6'>
      <QuizComponentTitleContent
        type={ComponentType.PROGRESSIVE_QUIZ}
        quiz={progressiveQuiz}
        mode={mode}
        currentLecture={currentLecture}
        saveComponent={saveComponent}
      />
      {(canAnswer || !attemptsLeft || (!progressiveQuiz.answerableQuestionsCount && !hardDeadlinePassed)) && (
        <>
          {areUnlimitedAttempts ? (
            <div className='label gray-3 mb-2'>
              {t.QUIZZES.UNLIMTED_ATTEMPTS()}
            </div>
          ) : (
            <>
              {progressiveQuiz.attemptsCompleted <= progressiveQuiz.maximumAttempts && (
                <div className='label gray-3 mb-2'>
                  {attemptsLeft
                    ? t.QUIZZES.ATTEMPT((progressiveQuiz.attemptsCompleted ?? 0) + 1, progressiveQuiz.maximumAttempts)
                    : t.QUIZZES.NO_ATTEMPTS_LEFT()}
                </div>
              )}
            </>
          )}
        </>
      )}
      {hardDeadlinePassed && (
        <div className='label gray-3 mb-2'>
          {t.QUIZZES.DEADLINE_PASSED()}
        </div>
      )}
      {(() => {
        const showReview = progressiveQuiz.attemptsCompleted || isMissed;

        const areTwoButtonsShown = showReview && canAnswer;

        // If progressive quiz only have statements
        if (!progressiveQuiz.answerableQuestionsCount) {
          return (
            <div className='d-flex'>
              {(notStarted || isInProgress) && (
                <Button
                  size='sm'
                  onClick={openQuiz}
                  disabled={isNotViewMode}
                  className={areTwoButtonsShown ? 'ml-2' : undefined}
                  data-qa={config.pendo.activities.progressiveQuiz[isInProgress ? 'resume' : 'start']}
                >
                  {isInProgress ? t.QUIZZES.RESUME_QUIZ() : t.QUIZZES.START()}
                </Button>
              )}
              {(isCompleted || hardDeadlinePassed) && (
                <Button
                  size='sm'
                  disabled={isNotViewMode}
                  onClick={() => reviewPreviousAttempt(true)}
                  data-qa={config.pendo.activities.progressiveQuiz.revealAnswers}
                >
                  {t.QUIZZES.REVEAL_ANSWERS()}
                </Button>
              )}
            </div>
          );
        }

        return (
          <div className='d-flex'>
            {showReview && (() => {
              const revealAnswers = (areUnlimitedAttempts || isFinished) && !progressiveQuiz.resumeQuiz;

              return (
                <Button
                  size='sm'
                  disabled={isNotViewMode}
                  variant={isFinished ? undefined : 'secondary'}
                  onClick={() => reviewPreviousAttempt(revealAnswers)}
                  data-qa={config.pendo.activities.progressiveQuiz[revealAnswers ? 'revealAnswers' : 'reviewPrevious']}
                >
                  {revealAnswers ? t.QUIZZES.REVEAL_ANSWERS() : t.QUIZZES.REVIEW_PREVIOUS()}
                </Button>
              );
            })()}
            {canAnswer && (
              <Button
                size='sm'
                onClick={() => openQuiz(!progressiveQuiz.resumeQuiz && !!progressiveQuiz.attemptsCompleted)}
                disabled={isNotViewMode || course.isContentManagementCollection}
                className={areTwoButtonsShown ? 'ml-2' : undefined}
                // eslint-disable-next-line no-nested-ternary
                data-qa={config.pendo.activities.progressiveQuiz[progressiveQuiz.resumeQuiz ? 'resume' : (progressiveQuiz.attemptsCompleted ? 'retry' : 'start')]}
              >
                {/* eslint-disable-next-line no-nested-ternary */}
                {progressiveQuiz.resumeQuiz ? t.QUIZZES.RESUME_QUIZ() : (progressiveQuiz.attemptsCompleted ? t.QUIZZES.DECAYED_POINTS.RETRY_QUIZ() : t.QUIZZES.START())}
              </Button>
            )}
          </div>
        );
      })()}
      <div css={styles} />
      <NvModal
        header={t.LECTURE_PAGES.COMPONENTS.QUIZ.MODAL.TITLE.EDIT()}
        show={showEditBasics}
        fullHeight={false}
        type={ModalType.DYNAMIC}
        onClose={handleModalClose}
        body={(
          <ProgressiveQuizAttemptSettingModal
            isEdit
            forwardOnModalClose={(fn) => {
              forwardedOnModalCloseRef.current = fn;
            }}
            initialValues={{
              quiz: progressiveQuiz.maximumAttempts,
              question: progressiveQuiz.questionMaximumAttempts,
            }}
            onCancel={() => setShowEditBasics(false)}
            onConfirm={async (payload) => {
              wrapThunkAction(dispatch(updateLectureComponent({
                catalogId,
                lecturePageId,
                componentData: {
                  id: lectureComponent.id,
                  type: lectureComponent.type,
                  ...payload,
                },
              }))).then(() => setShowEditBasics(false));
            }}
          />
        )}
      />
      <NvModal
        show={showQuestionModal}
        fullHeight={false}
        type={ModalType.NO_DIALOG}
        onClose={closeQuestionModal}
        body={(
          <div className='modal-page'>
            <QuizModal
              mode={modalModeData.progressiveQuizMode}
              closeModal={closeQuestionModal}
              reveal={modalModeData.reveal}
              lectureComponentId={lectureComponentId}
              questionIndex={modalModeData.initialQuestionIndex}
              forwardOnQuestionsModalCloseRef={forwardOnQuestionsModalCloseRef}
            />
          </div>
        )}
      />
    </div>
  );
};

export default ProgressiveQuizLectureComponent;
