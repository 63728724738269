import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { css } from '@emotion/core';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import t from 'react-translate';
import { AngularContext } from 'react-app';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

// redux
import { useAppDispatch } from 'redux/store';
import { getFlatCourseAliases } from 'redux/selectors/course';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';
import { ComponentTrueType, ComponentType, LectureComponent } from 'redux/schemas/models/lecture-component';

import { QuestionAttempts, QuizAttempts } from 'quizzes/components/quiz-animations';
import { extraLargeSpacing, threeQuartersSpacing } from 'styles/global_defaults/scaffolding';
import { ModalWorkflow } from 'lecture_pages/components/workflows';
import { FormAttemptsSetting } from 'quizzes/components/attempts-setting';
import QuizTypeCard, { QuizType } from 'quizzes/components/quiz-type-card';
import NvIcon from 'shared/components/nv-icon';
import { gray5 } from 'styles/global_defaults/colors';
import { ModalWorkflowContext, ModalWorkflowHandlerProps } from 'lecture_pages/components/workflows/modal-workflow';
import { DeepPartial } from 'utility-types';
import NvTextInput from 'shared/components/inputs/nv-text-input';
import { config } from '../../../config/pendo.config.json';

enum ModalCase {
  TYPE = 'type',
  LEGACY = 'legacy',
  PROGRESSIVE = 'progressive',
}

type OnConfirm = (
  lectureComponent?: DeepPartial<LectureComponent>,
  skipSave?: boolean,
  customType?: ComponentTrueType,
  customWorkflow?: DeepPartial<ModalWorkflow<ComponentTrueType>>
) => Promise<void>;

type QuizTypeSelectionModalContentProps = {
  onAllQuestionViewSelect: () => void,
  onSingleQuestionViewSelect: () => void,
};

export const QuizTypeSelectionModalContent = (props: QuizTypeSelectionModalContentProps) => {
  const { onAllQuestionViewSelect, onSingleQuestionViewSelect } = props;

  const styles = css`
    .quiz-type-option {
      width: 200px;
      height: 200px;
      background-color: ${gray5};
      margin-bottom: ${threeQuartersSpacing}px;
    }
  `;

  return (
    <div css={styles} className='pl-5 pr-5 pt-1'>
      <p className='mb-6 text-regular gray-2'>{t.QUIZZES.PROGRESSIVE_QUIZ_BASICS.ADD_QUIZ_DESCRIPTION()}</p>
      <div className='d-flex flex-row justify-content-between'>
        <QuizTypeCard
          type={QuizType.CLASSIC}
          onSelect={onAllQuestionViewSelect}
          dataQa={config.pendo.lectureEdit.legacyQuiz}
        />
        <QuizTypeCard
          type={QuizType.PROGRESSIVE}
          onSelect={onSingleQuestionViewSelect}
          dataQa={config.pendo.lectureEdit.progressiveQuiz}
        />
      </div>
    </div>
  );
};

type Props = {
  onCancel: () => void,
  onConfirm: OnConfirm,
  forwardOnModalClose: (fn: (e: Event, closeModal: Function) => void) => void,
} & ModalWorkflowHandlerProps;

const QuizBasicSettingModal = (props: Props) => {
  const { mode, forwardOnModalClose, lectureComponent, onCancel, onConfirm, ...rest } = props;

  const isLegacyQuiz = lectureComponent.type === 'QuizLectureComponent';

  const editStep = isLegacyQuiz ? ModalCase.LEGACY : ModalCase.PROGRESSIVE;

  const [step, setStep] = React.useState<ModalCase>(mode === 'new' ? ModalCase.TYPE : editStep);
  const { settings, setSettings } = React.useContext(ModalWorkflowContext);

  React.useLayoutEffect(() => {
    if (step === ModalCase.TYPE) {
      setSettings({
        ...settings,
        modalWidth: 800,
        title: () => t.QUIZZES.PROGRESSIVE_QUIZ_BASICS.SELECT_QUIZ_TYPE(),
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  if (step === ModalCase.TYPE) {
    const handleLegacyQuiz = () => {
      setSettings({
        ...settings,
        modalWidth: undefined,
        title: () => t.LECTURE_PAGES.COMPONENTS.QUIZ.MODAL.TITLE.ADD(),
      });
      setStep(ModalCase.LEGACY);
    };

    const openProgressiveQuizModal = () => {
      setSettings({
        ...settings,
        modalWidth: 800,
        title: () => t.QUIZZES.PROGRESSIVE_QUIZ_BASICS.QUIZ_BASICS(),
      });
      setStep(ModalCase.PROGRESSIVE);
    };

    return (
      <QuizTypeSelectionModalContent
        onAllQuestionViewSelect={handleLegacyQuiz}
        onSingleQuestionViewSelect={openProgressiveQuizModal}
      />
    );
  }

  if (step === ModalCase.LEGACY) {
    return (
      <AllQuestionViewQuizAttemptSettingModal
        onConfirm={onConfirm}
        onCancel={onCancel}
      />
    );
  }

  return (
    <ProgressiveQuizAttemptSettingModal
      onCancel={onCancel}
      onConfirm={onConfirm}
      forwardOnModalClose={forwardOnModalClose}
    />
  );
};

type ProgressiveQuizModalProps = {
  onCancel: () => void;
  onConfirm: OnConfirm;
  isEdit?: boolean,
  initialValues?: ProgressiveQuizFormValues;
  forwardOnModalClose: (fn: (e: Event, closeModal: Function) => void) => void,
};

type ProgressiveQuizFormValues = {
  quiz: number;
  question: number;
};

const validationSchema = Yup.object().shape({
  quiz: Yup.number(),
  question: Yup.number(),
});

type AllQuestionViewQuizAttemptSettingModalProps = {
  onCancel: () => void;
  onConfirm: OnConfirm;
  isEdit?: boolean,
  currentAttempts?: number,
};

enum AttemptType {
  UN_LIMITED = 'unlimited',
  LIMITED = 'limited',
}

type AllQuestionViewQuizFormValues = {
  attemptType: AttemptType;
  attempts: number | string;
};

export const AllQuestionViewQuizAttemptSettingModal = (props: AllQuestionViewQuizAttemptSettingModalProps) => {
  const { onConfirm, currentAttempts, onCancel, isEdit } = props;

  const courseAliases = useSelector(getFlatCourseAliases);
  const dispatch = useAppDispatch();

  const [selectedValue, setSelectedValue] = useState(AttemptType.LIMITED);

  const ValidationSchema = Yup.object().shape({
    attemptType: Yup.string(),
    attempts: selectedValue === AttemptType.LIMITED ? Yup.number()
      .transform(value => (Number.isNaN(value) ? undefined : value))
      .required(t.VALIDATION.REQUIRED())
      .typeError(t.VALIDATION.POSITIVE_INTEGER())
      .integer(t.VALIDATION.POSITIVE_INTEGER())
      .positive(t.VALIDATION.POSITIVE_INTEGER()) : Yup.number(),
  });

  const form = useForm<AllQuestionViewQuizFormValues>({
    mode: 'onChange',
    defaultValues: {
      attemptType: AttemptType.LIMITED,
      attempts: null,
    },
    resolver: yupResolver(ValidationSchema),
  });

  const { formState, handleSubmit, setValue, getValues } = form;

  const { isValid, isDirty } = formState;

  useEffect(() => {
    if (currentAttempts !== undefined) {
      if (currentAttempts === 0 || !currentAttempts) {
        setSelectedValue(AttemptType.UN_LIMITED);
      } else {
        setValue('attempts', currentAttempts);
      }
    }
  }, [currentAttempts, setValue]);

  const styles = css`
    .number-input {
      max-width: 80px;
    }
  `;

  const handleRadioInput = (e) => {
    if (e.target.value === AttemptType.LIMITED) {
      setValue('attempts', '');
    } else {
      setValue('attempts', 0);
    }
    setValue('attemptType', e.target.value, { shouldDirty: true });
    setSelectedValue(e.target.value);
  };

  const handleCancel = () => {
    if (isDirty) {
      dispatch(openConfirmationDialog({
        onConfirm: () => onCancel(),
        cancelText: t.FORM.CANCEL(),
        confirmText: t.FORM.YES_SURE(),
        title: t.FORM.UNSAVED_CHANGES.ARE_YOU_SURE(),
      }));
    } else {
      onCancel();
    }
  };

  const handleSaveQuiz = () => {
    onConfirm({
      quiz: {
        maximumAttempts: selectedValue === AttemptType.LIMITED ? getValues().attempts as number : 0,
      },
    }, false, ComponentType.QUIZ);
  };

  return (
    <div css={styles} className='d-flex flex-column'>
      <p className='mb-0'>{t.LECTURE_PAGES.COMPONENTS.QUIZ.MODAL.TYPE.DECRIPTION()}</p>
      <FormProvider {...form}>
        <form>
          <div className='radio'>
            <input
              checked={selectedValue === AttemptType.LIMITED}
              type='radio'
              name='attemptType'
              onChange={handleRadioInput}
              value={AttemptType.LIMITED}
              id={AttemptType.LIMITED}
            />
            <label htmlFor={AttemptType.LIMITED}>
              <div>
                {t.LECTURE_PAGES.COMPONENTS.QUIZ.MODAL.TYPE.ASSESSMENT.TITLE()}
              </div>
              <div className='text-gray-2'>
                {t.LECTURE_PAGES.COMPONENTS.QUIZ.MODAL.TYPE.ASSESSMENT.DESCRIPTION(courseAliases)}
              </div>
            </label>
          </div>
          <div className='radio'>
            <input
              checked={selectedValue === AttemptType.UN_LIMITED}
              type='radio'
              name='attemptType'
              onChange={handleRadioInput}
              value={AttemptType.UN_LIMITED}
              id={AttemptType.UN_LIMITED}
            />
            <label htmlFor={AttemptType.UN_LIMITED}>
              <div>
                {t.LECTURE_PAGES.COMPONENTS.QUIZ.MODAL.TYPE.PRACTICE.TITLE()}
              </div>
              <div className='text-gray-2'>
                {t.LECTURE_PAGES.COMPONENTS.QUIZ.MODAL.TYPE.PRACTICE.DESCRIPTION(courseAliases)}
              </div>
            </label>
          </div>
          {selectedValue === AttemptType.LIMITED && (
          <div className='mb-5'>
            <label>
              {t.LECTURE_PAGES.COMPONENTS.QUIZ.MODAL.MAXMIMUM_ATTEMPTS()}
            </label>
            <NvTextInput
              withForm
              type='number'
              className='number-input'
              name='attempts'
              required
              showErrorWithoutFocus
            />
          </div>
          )}
          <div className='d-flex justify-content-center'>
            <Button
              onClick={handleCancel}
              variant='secondary'
            >{t.FORM.CANCEL()}
            </Button>
            <Button
              disabled={!isDirty || (!isValid && selectedValue === AttemptType.LIMITED)}
              variant='primary'
              className='ml-2'
              onClick={handleSubmit(handleSaveQuiz)}
              type='submit'
            >{isEdit ? t.FORM.UPDATE() : t.LECTURE_PAGES.COMPONENTS.QUIZ.MODAL.NEXT()}
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export const ProgressiveQuizAttemptSettingModal = (props: ProgressiveQuizModalProps) => {
  const {
    onCancel,
    onConfirm,
    initialValues,
    isEdit = false,
    forwardOnModalClose,
  } = props;

  const dispatch = useAppDispatch();
  const courseAliases = useSelector(getFlatCourseAliases);

  const { injectServices } = React.useContext(AngularContext);
  const [StateManager] = injectServices(['StateManager']);

  const forwardOnModalCloseRef = React.useRef(forwardOnModalClose);
  forwardOnModalCloseRef.current = forwardOnModalClose;

  const [animate, setAnimate] = React.useState<'quiz' | 'question'>('quiz');

  const form = useForm<ProgressiveQuizFormValues>({
    mode: 'onChange',
    defaultValues: initialValues ?? {
      quiz: null,
      question: null,
    },
    resolver: yupResolver(validationSchema),
  });

  const { formState, handleSubmit, watch } = form;

  const { isValid, isDirty } = formState;

  React.useEffect(() => StateManager.registerStateChangeStart(
    () => isDirty,
    'shared/templates/modal-navigate-away.html',
    'FORM.UNSAVED_CHANGES.NAVIGATE_AWAY',
  ),
  [isDirty, StateManager]);

  React.useEffect(() => {
    forwardOnModalCloseRef.current((e: Event, closeModal: Function) => {
      if (isDirty) {
        e.preventDefault();

        dispatch(openConfirmationDialog({
          onConfirm: () => closeModal(),
          cancelText: t.FORM.CANCEL(),
          confirmText: t.FORM.YES_SURE(),
          title: t.FORM.UNSAVED_CHANGES.CLOSE_WINDOW.TITLE(),
          bodyText: t.FORM.UNSAVED_CHANGES.CLOSE_WINDOW.DESCRIPTION(),
        }));
      }
    });
  }, [isDirty, dispatch]);

  const styles = css`
    .button-bar {
      margin-top: ${extraLargeSpacing}px;
    }
  `;

  const handleSaveQuiz: SubmitHandler<ProgressiveQuizFormValues> = ({ quiz, question }) => {
    onConfirm({
      progressiveQuiz: {
        maximumAttempts: quiz,
        questionMaximumAttempts: question,
      },
    }, false, ComponentType.PROGRESSIVE_QUIZ);
  };

  const questionAttemptsFormValue = watch('question');

  const showReducingAttemptsWarning = questionAttemptsFormValue < initialValues?.question;

  return (
    <FormProvider {...form}>
      <div css={styles} className='pl-2 pr-2 pb-4'>
        <div className='text-medium mb-6 gray-2'>
          {t.QUIZZES.PROGRESSIVE_QUIZ_BASICS.MODAL_DESCRIPTION(courseAliases)}
        </div>
        <div>
          <FormAttemptsSetting
            name='quiz'
            canBeUnlimited
            className='mb-8'
            animate={animate === 'quiz'}
            AnimationComponent={QuizAttempts}
            optionTranslation={t.QUIZZES.PROGRESSIVE_QUIZ_BASICS.N_QUIZ_ATTEMPTS}
            placeholder={t.QUIZZES.PROGRESSIVE_QUIZ_BASICS.SELECT_QUIZ_ATTEMPTS()}
            description={t.QUIZZES.PROGRESSIVE_QUIZ_BASICS.QUIZ_ATTEMPT_DESCRIPTION(courseAliases)}
            dataQa={config.pendo.activities.progressiveQuiz.basicsModal.quizAttempt}
            onAnimationEnd={() => {
              setTimeout(() => {
                setAnimate('question');
              }, 500);
            }}
          />
          <FormAttemptsSetting
            name='question'
            animate={animate === 'question'}
            AnimationComponent={QuestionAttempts}
            info={t.QUIZZES.PROGRESSIVE_QUIZ_BASICS.QUESTION_ATTEMPT_INFO()}
            optionTranslation={t.QUIZZES.PROGRESSIVE_QUIZ_BASICS.N_QUESTION_ATTEMPTS}
            placeholder={t.QUIZZES.PROGRESSIVE_QUIZ_BASICS.SELECT_QUESTION_ATTEMPTS()}
            description={t.QUIZZES.PROGRESSIVE_QUIZ_BASICS.QUESTION_ATTEMPT_DESCRIPTION(courseAliases)}
            dataQa={config.pendo.activities.progressiveQuiz.basicsModal.questionAttempt}
            onAnimationEnd={() => {
              setTimeout(() => {
                setAnimate('quiz');
              }, 500);
            }}
            renderAfter={() => {
              if (showReducingAttemptsWarning) {
                return (
                  <div className='d-flex align-items-center pt-2'>
                    <NvIcon icon='warning' size='small' className='mr-3 text-danger' />
                    <div className='text-small text-danger'>{t.QUIZZES.PROGRESSIVE_QUIZ_BASICS.REDUCING_QUESTION_ATTEMPTS_WARNING(courseAliases)}</div>
                  </div>
                );
              }

              return null;
            }}
          />
        </div>
        <div className='button-bar'>
          <Button
            className='mt-2'
            onClick={onCancel}
            variant='secondary'
            data-qa={config.pendo.activities.progressiveQuiz.basicsModal.cancel}
          >
            {t.FORM.CANCEL()}
          </Button>
          <Button
            className='mt-2'
            disabled={!isValid || !isDirty}
            onClick={handleSubmit(handleSaveQuiz)}
            data-qa={config.pendo.activities.progressiveQuiz.basicsModal[isEdit ? 'update' : 'create']}
          >
            {isEdit ? t.FORM.UPDATE() : t.QUIZZES.PROGRESSIVE_QUIZ_BASICS.CREATE_QUIZ()}
          </Button>
        </div>
      </div>
    </FormProvider>
  );
};

export default QuizBasicSettingModal;
